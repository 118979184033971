import React from "react";

// Styles
import styled from 'styled-components';
import { Container } from 'components/Theme/Grid';

import HeroGuideCard from "./HeroGuideCard";
import GuideCard from "./GuideCard";

const GuideContainer = styled(Container)`
  padding:3rem;
  background-color:${p => p.theme.brandSecondary};
`;

const FirstGuideContainer = styled.div`
  width:50%;
`;

const RemainderGuides = styled.div`
  width:50%;
`;

const GuideCards = ({data}) => {

  const firstGuide = data[0];
  const remainderGuides = data.slice(1);

  return ( 
    <GuideContainer>
      <FirstGuideContainer>
        <HeroGuideCard data={firstGuide} />
      </FirstGuideContainer>

      <RemainderGuides>
        {remainderGuides.map((guide) => {
          return <GuideCard data={guide} />
        })}
      </RemainderGuides>
    </GuideContainer>
  )
}

export default GuideCards
