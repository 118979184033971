import React from "react";
import Layout from "components/layout";
import { graphql } from 'gatsby'

// Styles
import styled from 'styled-components';
import { h1 } from 'components/Theme/Copy';

// Components
import GuideCards from "components/Page/Guides/GuideCards";


const Headline = styled(h1)`
  margin-bottom:3rem;
  font-size:3.5rem;
  text-align:center;
`;

const GettingStarted = ({data}) => {
  const guides = data.allContentfulGuide.nodes
  
  return ( 
    <Layout >
      <Headline>Getting Started</Headline>
      <GuideCards data={guides} />
    </Layout >
  )
}

export default GettingStarted


// language=GraphQL
export const pageQuery = graphql`
  query GuidesQuery {
    allContentfulGuide {
      nodes {
        handle
        name
        subheadline
        coverPhoto {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        videos {
          file {
            url
          }
        }
      }
    }
  }
`;